<template>
  <div>
    <img src="@/assets/img/img999.png" class="w100">
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped></style>
